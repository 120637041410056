.legendContainer {
  background-color: #91ccff;
  border: 1px solid black;
  display: flex;
  flex-flow: row wrap;
  //justify-content: space-evenly;
  padding: 1em;
  margin: 1em;

  > div {
    display: flex;
    flex-direction: row;
    margin-right: .5em;
    margin-bottom: .5em;

    > div {
      margin-right: .5em;
    }
  }
}