.buttonToolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60px;

  .buttonRow {
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .buttonColumn {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .backBtn {
    background-color: #42d355;
  }

  .muteBtn {
    background-color: #39a5fe;
  }

  .playBtn {
    background-color: #a549aa;
  }

  .quitBtn {
    background-color: #3d50ff;
  }

  .printTestBtn {
    color: white;
    background-color: #ea8621;
    text-transform: uppercase;
    font-size: 10pt;
    margin: .75em;
  }

  .progressBtn {
    color: white;
    background-color: #ff3223;
    text-transform: uppercase;
    margin: .1em;
    font-size: 10pt;
  }

  .helpBtn {
    background-color: #e7170a;
  }

  .lessonListBtn {
    background-color: #004cff;
  }

  .replayBtn {
    background-color: #dc8222;
  }

  .nextBtn {
    background-color: #42d355;
  }
}
