$sliderSize: 180px;

.slidingContainer {
  
  display:flex;
  flex-direction: column;

  .slideEquation {
    font-size: 200%;
  }

  .pickOne {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pictures {
      width: 50%;
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .slide {
        display: block;
        margin: 1em;
        width: $sliderSize;
        height: $sliderSize;

        img {
          border: 2px solid black;
          margin: .12em;
          width: $sliderSize;
          height: $sliderSize;
        }

        img:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mathProblem {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;

    .picture {
      width: 49%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .problem {
      width: 49%;
      height: 60%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      button {
        background-color: #ea8621;
        color: white;
        text-transform: uppercase;
        margin-left: 2em;
        margin-bottom: 1em;
        font-size: 14pt;
    }
    }
  }

  .instructions {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff3cb;

    div:first-child {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: .5em;
    }

    button {
      background-color: #ea8621;
      color: white;
      text-transform: uppercase;
      font-size: 14pt;
    }
  }

}