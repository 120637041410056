.playpause {
  background-image: url(../assets/image/media_play_pause_resume.png);
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  //background-size: contain;
  background-position: center;
}
