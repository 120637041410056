@import './variables.scss';

.progressReportContainer {
  background-image: url("../../assets/image/LessonListBackground.png");
  display: flex;
  flex-direction: row;

  .report {
    height: 100%;
    overflow: scroll;
    flex-basis: 75%;
    flex-grow: 0;
    flex-shrink: 0;
    
    .columnNames {
      background-color: white;
      display: flex;
      flex-direction: row;
      font-size: .9em;
      background-color: #eeee;
    
      .small {
        flex-basis: $smallCol;
      }

      .medium {
        flex-basis: $medCol;
      }

      .medium-large {
        flex-basis: $tinyCol*2;
      }

      .large {
        flex-basis: $lgCol;
      };
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;

    .header {
      font-size: 175%;
      font-weight: bold;
      margin-top: 1em;
    }
    .headerName {
      font-size: 150%;
      font-weight: bold;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .printBtn {
      background-color: #ea8621;
      color: white;
      text-transform: uppercase;
      align-self: flex-end;
      // i am at a loss why this button is positioning strangely
      margin-top: 4em;
      margin-right: 1em;
      font-size: 14pt;
    }
  }

  @media print {
    @page {size: portrait}

    background-image: none;
    font-size: 8pt;
    
    .info { display: none }
    .report {
      overflow: visible;
      flex-basis: 100%;

      .columnNames {
        margin-top: .3em;
        background-color: transparent;
      }
    }


  }

}