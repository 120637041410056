.testResultContainer {
  display: flex;
  flex-direction: column;

  .resultRow {
    border: 2px solid black;
    width: 140px;
    //border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    justify-content: center;
  }

  .resultRow > div:nth-child(2) {
    border-left: 2px solid black
  }

  .resultRow > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .resultColumn {
    flex-basis: 100%;
  }

  .header {
    border-bottom: 2px solid black;
    padding: .5em;
    font-size: 70%;
    align-self: stretch;
    flex-basis: 100%;

  }

  .correct {
    background-color: #66cc66;
  }

  .score {
    background-color: #6699ff;
  }

  .time {
    background-color: #ffcc66;
  }

  .result {
    background-color: white;
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: 16pt;
    font-weight: 700;
  }
}
