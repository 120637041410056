@import './variables.scss';

.progressSectionContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  font-size: 10pt;
  font-weight: 600;
  height: 2.5em;
  background-color: white;

  .progress {
    flex-basis: $tinyCol;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .type {
    flex-basis: $tinyCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .section {
    flex-basis: $smallCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    flex-basis: $lgCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .nameText {
      padding-left: .5em;
      text-align: left;
    }
  }

  .facts {
    flex-basis: $medCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .completedOn {
    flex-basis: $medCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .correct {
    flex-basis: $smallCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .score {
    flex-basis: $smallCol;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .time {
    flex-basis: $medCol;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filler {
    flex-basis: $fillerCol;
    //background-color: #cecbcd;
    background-color: #dfdbde;
    padding: 1px; // this makes up for the missing borders so things line up vertically
  }

  @media print {
    height: 1.7em;
    background-color: transparent;
    border-left: 1px solid black;
    border-right: 1px solid black;
    font-size: 8pt;

    .filler {
      background-color: transparent;
    }

    .progress,
    .type,
    .section,
    .name,
    .facts,
    .completedOn {
      padding: .1em;
    }

    .correct,
    .score,
    .time { 
      padding-top: .5em;
    }
  }
}