.lessonContainerContainer {
  position: relative;

  .lessonLegend {
    position: absolute;
    left: 650px;
    top: 300px;
  }

  .nextPageArrow {
    position: absolute;
    right: 10px;
    bottom: 50px;
    font-size: 18pt;
    font-weight: 600;
    cursor: default;
  }
  .nextPageButton {
    position: absolute;
    background-color: red;
    color: white;
    font-size: 18pt;
    right: 10px;
    bottom: 60px;
  }

  .prevPageArrow {
    position: absolute;
    right: 236px;
    bottom: 50px;
    font-size: 18pt;
    font-weight: 600;
    cursor: default;
  }
  .prevPageButton {
    position: absolute;
    background-color: red;
    color: white;
    font-size: 18pt;
    right: 226px;
    bottom: 60px;
  }

  .moreLessons {
    position: absolute;
    color: red;
    font-size: 16pt;
    font-weight: 800;
    line-height: 90%;
    width: 88px;
    height: 42px;
    right: 140px;
    bottom: 54px;
  }

  .directionsButton {
    position: absolute;
    background-color: #5a5aff;
    color: white;
    font-size: 10pt;
    width: 200px;
    height: 2.5em;
    right: 135px;
    bottom: 10px;
  }
}

.lessonContainer {
  background-image: url("../../assets/image/LessonListBackground.png");
  display: flex;
  flex-direction: row;

  .lessonColumn {
    width: 30%;
    margin: 1em;
  }
  
  .lesson {
    border: 1px solid black;
    background-color: white;
  
    .header {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid black;
      background: #99ccff;
      font-size: 8.5pt;
      .type {
        width: 20%;
      }
      .section {
        width: 10%;
      }
      .name {
        width: 50%;
        font-weight: 800;
      }
      .facts {
        width: 20%;
      }
    }

    .sectionContainer {
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: row;
      font-size: 10pt;
      font-weight: 500;
      cursor: pointer;
      .progress {
        width: 10%;
        font-size: 14pt;
      }
      .type {
        width: 10%;
        border-right: 1px solid black;
        font-size: 14pt;
      }
      .section {
        width: 10%;
        border-right: 1px solid black;
      }
      .name {
        width: 50%;
        border-right: 1px solid black;
      }
      .facts {
        width: 20%;
      }
    }

    .sectionContainerContainer :hover {
      background-color: #FFFFD0;
    }

    .sectionContainerTestComplete {
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: row;
      font-size: 10pt;
      font-weight: 500;
      color: gray;
      .clearButton {
        background-color: #dd8500;
        color: white;
        border-width: 0px;
      }
      .clearButtonArea {
        width: 20%;
        border-right: 1px solid black;
        font-size: 14pt;
      }
      .section {
        width: 10%;
        border-right: 1px solid black;
      }
      .name {
        width: 50%;
        border-right: 1px solid black;
      }
      .facts {
        width: 20%;
      }
    }
  }
}

.dialogText {
  color: #0d2d70;
  text-align: center;
}

.buttonRow {
  margin: 1ex;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.yesButton {
  font-size: 16pt;
  color: white;
  margin-right: 2em;
  background-color: #5a5aff;
}
.noButton {
  font-size: 16pt;
  color: white;
  margin-left: 2em;
  background-color: red;
}

.passwordInput {
  width: 100%;
  outline: none;
  border: 2px solid black;
  font-size: 18pt;
  color: black;
  text-align: center;
}

.passwordLabel {
  display: block;
  width: 100%;
  text-align: center;
  color: #0d2d70;
  font-size: 18pt;
  font-weight: 600;
  text-align: center;
}


.passwordButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.passwordPrompt {
  color: black;
  font-size: 10pt;
  visibility: hidden;
  text-align: left;
  margin-right: 1em;
}

.passwordEnterButton {
  background-color: #dd8500;
  color: white;
  font-size: 16pt;
}

.closeX {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: default;
  font-size: 16pt;
}
