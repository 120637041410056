.loginInput {
  width: 100%;
  outline: none;
  border: 2px solid black;
  font-size: 18pt;
  color: black;
  text-align: center;
}

.loginLabel {
  display: block;
  width: 100%;
  color: #0d2d70;
  font-size: 18pt;
  font-weight: 600;
  text-align: center;
}

.logo {
  height: 372px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loginButton {
  font-size: 20pt;
  color: white;
  margin-right: 2em;
  background-color: #5a5aff;
}
.tryitButton {
  display: none;
  font-size: 20pt;
  color: white;
  margin-left: 2em;
  background-color: #dd8500;
}

.modalButtonRow {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}


.fullnamePrompt {
  color: black;
  font-size: 12pt;
  font-weight: 600;
  text-align: left;
  margin-right: 1em;
}

.loginEnterButton {
  background-color: #dd8500;
  color: white;
  font-size: 16pt;
  height: 22pt;
}

.notSubscribed {
  // height: 96%;
  // width: 96%;
  // margin-top: 2%;
  // margin-left: 2%;
  color: black;
  text-align: center;
  font-size: 14pt;
}

.closeButton {
  position: absolute;
  font-size: 16pt;
  right: 5px;
  bottom: 5px;
  color:white;
  background-color: #dd8500;
}

.closeX {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: default;
  font-size: 16pt;
}
