.equationContainer {
  
  .times {
    display: flex;
    flex-direction: row;
  }

  label {
    margin-left: .5em;
    margin-right: .5em;
  }

  .finalAnswer {
    margin: .5em;
    height: 1.25em;
    width: 1.25em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 2px solid white;
    font-weight: bold;
  }

  .finalAnswerWrong {
    color: red;
    text-decoration: line-through;
  }

  input {
    margin: .5em;
    font-size: 100%;
    border: 1.75px dashed black;
    height: 1.25em;
    width: 1.25em;
    text-align: right;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .invalidAnswer {
    color: red,
  }

  .equationRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .times {
      label:first-child {
        align-self: center;
      }
    }
  }

  .equationColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label:nth-child(2) {
      margin-left: 1.25em;
      margin-bottom: 0;
    }
    

    .times {
      border-bottom: 2px solid black;

      label {
        margin: .12em;
      }
    }
  }
 
}