.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #205cd4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logosContainer {
  display: flex;
  flex-direction: row;
}

.City-creek-logo {
  text-align: left;
}

.logoSpan {
  width: 385px;
}

.TimesAliveLogo {
  margin: auto;
}

.Page-header {
  background-color: #3e2be0;
  color: white;
  display: flex;
  flex-direction: row;
  height: 60px;
  line-height: 60px;
  font-size: 16pt;
  font-weight: 600;
  text-align: center;
}
.one-third-row {
  width: 33.33%;
}
.App-title {
  text-align: left;
  margin-left: 4em;
}

.Directions-header {
  position: relative;
  background-color: #f2f2f2;
  color: black;
  height: 48px;
  font-size: 20px;
  font-weight: 600;
  margin: 1em;
  margin-left: 3em;
  text-align: left;
}
.Directions-header-text {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Directions {
  margin: 1em;
  margin-left: 4em;
  text-align: left;
}

.Times-Alive-container {
  background-color: turquoise;
  color:white;
  margin: auto;
  height: 760px;
  width: 1010px;
}

.Times-Alive {
  background-color: blue;
  border: 2px solid;
  border-color: blue;
  margin: auto;
  height: 100%;
  width: 100%
}

.Times-tables-the-fun-way {
  padding-top: 10px;
}

.lesson-title {
  background-color: #6df367;
  color: black;
  padding-left: .25em;
  padding-right: .25em;
}
.lesson-section {
  background-color: black;
  color: white;
  padding-left: .5em;
  padding-right: .5em;
}
.lesson-name {
  padding-left: .5em;
}

.Times-Alive-lessons-area {
  background-color: white;
  color: black;
  border: 2px solid;
  border-color: blue;
  margin: auto;
  height: 530px;
  width: 980px;
  position: relative;
}

.Times-Alive-lessons-area > :first-child {
  width: 100%;
  height: 100%;
}

.Times-Alive-lesson {
  height: 100%;
  width: 100%;
}

.button-toolbar-area {
  background-color: #d1e4ec;
  height: 80px;
  width: 100%;
}

.Post-ta-gap {
  height: 40px;
}

.Page-footer {
  background-color: #3e2be0;
  color: white;
  height: 80px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

@media print {
  .button-toolbar-area,
  .Times-tables-the-fun-way,
  .Page-header,
  .City-creek-logo,
  .TimesAliveLogo,
  .App-title,
  .Directions,
  .Directions-header,
  .Post-ta-gap,
  .Page-footer { display: none }

  .Times-Alive-container {
    width: 100%;
  }
  
  .Times-Alive, 
  .Times-Alive-lessons-area {
    background-color: transparent;
    border: none;
    max-width: 580pt;
  }
}