.teacherDirectionsContainer {
  position: relative;

  .printButton {
    position: absolute;
    font-size: 14pt;
    right: 90px;
    bottom: 30px;
    background-color: #dd8500;
    color: white;
  }


  .teacherDirections {
    display: flex;
    flex-direction: row;
    background-color: #fdffe3;
    text-align: left;
    font-size: 9pt;
    font-weight: 500;
    height: 524px;
    width: 980px;
    overflow: auto;

    p {
      font-weight: 800;
      
    }
    p + ol {
      margin-top: -12px;
    }
    p + ul {
      margin-top: -12px;
    }

    ul.noBullets {
      list-style-type: none;
    }
  
    .column {
      width: 50%;
      margin-right: 20px;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
  
  @media print {
    
    .teacherDirections {
      flex-direction: column;
      background-color: transparent;
      font-size: 11pt;
      width: 100%;
      overflow: visible;

      .column {
        width: 100%;
      }
    }
    .printButton { 
      display: none 
    }
  }
}