.progressLessonContainer {
  display: flex;
  flex-direction: column;

  .header {
    background-color: #91ccff;
    border-bottom: 1px solid black;
  }

  @media print {
    .header {
      background-color: transparent;
      //border-left: 1px solid black;
      padding: .5em;
    }
  }

  page-break-inside: avoid;
}