.roundButtonContainer {
  height: 80px;
  width: 90px;

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .icon {
      color: white;
      width: 2em;
      height: 2em;
    }
  
    button {
      width: 4em;
      height: 4em;
      border-radius: 50%;
    }
  
    button:disabled {
      background-color: grey;
    }
  
    button:focus {
      // advised that we don't get rid of this entirely for accessibilty reasons
      // https://www.a11yproject.com/posts/2013-01-25-never-remove-css-outlines/
      outline: thin dotted;
    }
  
    label {
      color: black;
      text-transform: uppercase;
      font-size: 80%;
    }
  }

  .tooltiptext {
    position: relative;
    z-index: 100;
    top: -150px;
    background-color: #91ccff; 
    color: black;
    padding: .5em;
    font-size: 100%;
    margin-left: .25em;
    margin-right: .25em;
    border-radius: 6px;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color:#91ccff transparent transparent transparent;
  }

}

/*
.roundButtonContainer:hover {
  border: 1px solid red;
}

 .roundButtonContainer:hover .tooltiptext {
   visibility: visible;
   position: relative;
   z-index: 100;
   top: -150px;
   //border: 1px solid black;
   background-color: #ea8621;
   color: white;
   padding: .5em;
   font-size: 75%;
   margin-left: .25em;
   margin-right: .25em;
   border-radius: 6px;
 }*/