.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .printHeader,
    .studentName {
      display: none;
    }

    .Times-Alive-lesson-indicator {
      background-color: blue;
      color: white;
      height: 40px;
      padding-left: 1em;
      font-weight: 700;
      font-size: 14pt;
      text-align: left;
    }  
  }

  .rightSide {
    display: none;
  }

  @media print {
    border-bottom: 5px solid black;

    .leftSide {

      .printHeader {
        height: 45px;
        width: 400px;
        display: inherit;
        /*margin-bottom: 1em;*/
      }

      .studentName {
        color: black;
        display: inherit;
      }

      .Times-Alive-lesson-indicator {
        color: black;
        background-color: transparent;
        padding-left: 0;
        margin-top: .2em;
        height: 30px;

        .lesson-title,
        .lesson-section {
          display: none;
        }
      }
    }
    
    .rightSide {
      color: black;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .phoneNumber {
        font-size: 100%;
      }

      .copyright {
        font-size: 60%;
        padding-bottom: 2em;
      }
    }

  }
}