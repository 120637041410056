.guessingContainer {

  .guessEquation {
    font-size: 200%;
  }

  .guessWithPicture {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .guessNoPicture {
    display: flex;
    flex-direction: column;
  }

  .instructions {
    display: flex;
    flex-direction: column;
    background-color: #fff3cb;
    align-items: flex-start;
    padding: .5em;
    font-size: 16pt;
  }

  .picture {

    img {
      max-height: 300px;
    }
  }

  .problem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .checkBtn {
      background-color: #ea8621;
      color: white;
      text-transform: uppercase;
      font-size: 14pt;
    }
  }

  .problemSpacer {
    height: 160px;
  }
}