.testContainer {
  display: flex;
  flex-direction: row-reverse;

  .testEquation {
    margin-left: .3em;
    margin-right: .3em;
    font-weight: 700;
  }

  .instructions {
    padding: 1em .5em 1em 1.5em;
    background-color: #ffcc00;
    border-left: 1px solid black;
    flex-basis: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ol {
      text-align: left;
      padding-left: 0;
      margin: 0;
      width: 174px;
      font-size: 120%;
    }
    
    button {
      background-color: #ff1f1f;
      color: white;
      font-size: 16pt;
      text-transform: uppercase;
    }
  }

  .questions {
    display: flex;
    flex-basis: 85%;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 92%;
    margin-top: 16px;

    .correctAnswer {
      color: #66cc66;
      font-weight: bold;
      height: 1em;
    }
  }

  .questions-4 {
    font-size: 200%;
    justify-content: center;
    align-items: center;
  }
  
  .questions-10 {
    justify-content: center;
    align-items: center;
    font-size: 200%;
    padding-left: 9%;
    padding-right: 9%;
  }

  .questions-20 {
    font-size: 120%;
    justify-content: center;
    align-items: center;
  }

  @media print {
    
    input,
    .finalAnswer {
      color: transparent;
      border: none;
    }
    .correctAnswer { height: .75em !important; }
    .instructions { 
      display: none 
    }
    .questions {
      padding-top: 1em;
      margin-left: 1.5em;
      margin-right: 1.5em;
      flex-basis: 100%;
      font-size: 148%;
    }

    .questions-10 {
      font-size: 230%;
    }

    .questions-20 {
      font-size: 220%;
    }
  }
  
}

.startButton {
  position: absolute;
  font-size: 16pt;
  right: 10px;
  bottom: 10px;
  color:white;
  background-color: #dd8500;
}

